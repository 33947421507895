@import "../../Imports.scss";

#projects {
  font-family: "Raleway", sans-serif;
  min-height: 100vh;

  .heading {
    text-align: center;
    padding-top: 80px;

    h1 {
      font-size: 4em;
      font-weight: lighter;
      margin-bottom: 0;
    }
  }

  .projects-showcase {
    margin-top: 40px;

    .top-arrows {
      text-align: center;
      margin-bottom: 15px;
      display: none;
    }

    .right-arrow {
      color: $primary;
      cursor: pointer;
      user-select: none;
      font-size: 3.5em;
    }

    .left-arrow {
      color: $primary;
      cursor: pointer;
      user-select: none;
      font-size: 3.5em;
    }

    .slider {
      position: relative;
      display: flex;
      justify-content: center;

      .slider-images {
        display: flex;

        .project {
          width: 620px;
          text-align: center;

          p {
            text-align: left;
            padding: 0 10px;
          }

          .project-icon {
            font-size: 1.8em;
            margin: 0 3px;
          }

          h1 {
            font-size: 1.5em;
          }

          a {
            color: #000;
          }

          .technologies {
            display: flex;
            flex-wrap: wrap;
            padding: 0 10px;
            margin-bottom: 15px;

            .technology {
              background: $dark;
              color: #fff;
              padding: 5px;
              border-radius: 5px;
              margin: 5px 10px 5px 0;
              font-size: 0.8em;
            }
          }
        }

        .project-2 {
          .technologies .technology {
            background: $primary;
            color: #fff;
            padding: 5px;
            border-radius: 5px;
            margin: 5px 10px 5px 0;
            font-size: 0.8em;
          }
        }

        .slider-image {
          width: 600px;
          max-height: 350px;
          border-radius: 8px;
          margin: 0 10px;
          box-shadow: 5px 5px 10px rgba(#c78282, 0.1);
        }
      }

      .right-arrow {
        position: absolute;
        top: 50%;
        right: calc(50% - 700px);
      }

      .left-arrow {
        position: absolute;
        top: 50%;
        left: calc(50% - 700px);
      }

      .slide {
        opacity: 0;
        transition-duration: 1s ease;
      }

      .slide.active {
        opacity: 1;
        transition-duration: 1s;
        // transform: scale(1.08);
      }
    }
  }

  .projects-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 80px;

    .project {
      width: calc(
        33.33% - 120px
      ); /* Make sure there's enough space for the project card to breathe */
      // border: 1px solid #eee;
      margin: 2px;
      padding: 40px 10px 0px 10px;
      text-align: center;
      border: 1px solid #ccc;

      img {
        width: 80%;
        height: 45%;
      }

      h2 {
        font-size: 1em;
      }

      p {
        font-size: 0.8em;
      }

      ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
          background-color: $primary;
          padding: 5px;
          margin: 2px;
          color: #fff;
          font-size: 0.7em;
          border-radius: 3px;
        }
      }

      .links {
        display: flex;
        justify-content: center;
        gap: 10px;

        a {
          text-decoration: none;
          color: $dark;
          padding: 5px 10px;
          border: 1px solid $dark;
          font-size: 0.8em;

          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

// @media only screen and (max-width: 1425px) {
//   #projects {
//     .projects-showcase {
//       .slider {
//         .right-arrow {
//           right: calc(50% - 550px);
//           font-size: 3.5em;
//         }

//         .left-arrow {
//           left: calc(50% - 550px);
//           font-size: 3.5em;
//         }

//         .slider-images {
//           .project {
//             width: 470px;
//           }

//           .slider-image {
//             width: 450px;
//           }
//         }
//       }
//     }
//   }

//   @media only screen and (max-width: 1130px) {
//     #projects {
//       .projects-showcase {
//         .slider {
//           .slider-images {
//             flex-direction: column;
//             align-items: center;

//             .project {
//               width: 620px;
//               margin-bottom: 30px;

//               p {
//                 text-align: center;
//               }

//               .technologies {
//                 justify-content: center;
//               }
//             }

//             .slider-image {
//               width: 600px;
//             }
//           }

//           .right-arrow,
//           .left-arrow {
//             display: none;
//           }
//         }

//         .top-arrows {
//           display: block;
//         }
//       }
//     }
//   }

//   @media only screen and (max-width: 780px) {
//     #projects {
//       .projects-showcase {
//         .slider {
//           .slider-images {
//             .project {
//               width: 80%;
//               text-align: center;
//             }

//             .slider-image {
//               width: 90%;
//             }
//           }
//         }

//         .top-arrows {
//           .right-arrow,
//           .left-arrow {
//             font-size: 2.5em;
//           }
//         }
//       }
//     }
//   }
// }

@media only screen and (max-width: 1200px) {
  #projects {
    .projects-gallery {
      .project {
        width: 40%;
      }
    }
  }
}

@media only screen and (max-width: 880px) {
  #projects {
    .projects-gallery {
      .project {
        width: 45%;
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  #projects {
    .projects-gallery {
      .project {
        width: 50%;
      }
    }
  }
}

@media only screen and (max-width: 710px) {
  #projects {
    .projects-gallery {
      .project {
        width: 60%;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  #projects {
    .projects-gallery {
      .project {
        width: 70%;
      }
    }
  }
}

@media only screen and (max-width: 505px) {
  #projects {
    .projects-gallery {
      .project {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  #projects {
    .projects-gallery {
      .project {
        width: 90%;
      }
    }
  }
}

@media only screen and (max-width: 390px) {
  #projects {
    .projects-gallery {
      .project {
        width: 95%;
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  #projects {
    .projects-gallery {
      .project {
        width: 100%;
      }
    }
  }
}
