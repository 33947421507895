@import "../../Imports.scss";

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Raleway", sans-serif;
  padding: 10px 20px;

  a {
    text-decoration: none;
    color: #000;
  }

  .logo-area {
    display: flex;
    align-items: center;

    img {
      height: 60px;
      padding-right: 12px;
    }

    h1 {
      font-size: 1.5em;
    }
  }

  .navbar {
    a {
      font-size: 1.3em;
    }

    a:not(:last-of-type) {
      margin-right: 25px;
    }
  }

  .navbar-mobile {
    display: none;

    .navbar-mobile-icon {
      height: 40px;
      cursor: pointer;
    }

    .navbar-mobile-container {
      position: absolute;
      left: 0;
      top: 80px;
      width: 100%;
      height: calc(100% - 80px);
      background: $dark;

      .navbar-mobile-items {
        display: flex;
        flex-direction: column;
        position: relative;
        top: calc(50% - 180px);

        a {
          display: block;
          text-align: center;
          color: $secondary;
          font-size: 1.5em;
        }

        a:not(:last-of-type) {
          margin-bottom: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  #header {
    .navbar {
      display: none;
    }

    .navbar-mobile {
      display: block;
    }
  }
}