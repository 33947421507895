@import "../../Imports.scss";

#skills {
    background: $pink;
    text-align: center;
    font-family: "Raleway", sans-serif;

    .heading {
        text-align: center;
        padding-top: 10px;

        h1 {
            font-size: 4em;
            font-weight: lighter;
            margin-bottom: 0;
        }
    }

    .skills {
        margin: 35px auto 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 60%;

        img {
            margin: 20px;
            max-height: 100px;
            max-width: 100%;
        }
    }
}