@import "../../Imports.scss";

#home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Raleway", sans-serif;
  margin-top: 30px;

  .info {
    position: relative;

    img {
      width: 110%;
      margin-left: -140px;
    }

    .info-text {
      position: absolute;
      top: 80px;
      left: 210px;
      width: 100%;
    }

    h2 {
      font-size: 3.5em;
      margin-bottom: 15px;
    }

    h1 {
      font-size: 5em;
      margin: 0;
      color: $secondary;

      span {
        color: #000;
      }
    }
  }

  .main-img {
    margin-right: 170px;
  }
}

.main-btns {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.contact-btn {
  text-align: center;
  margin-top: 30px;

  a {
    background: none;
    border: 1px solid;
    padding: 8px 15px;
    cursor: pointer;
    font-family: "Raleway", sans-serif;
    font-size: 1.2em;
    color: #000;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1650px) {
  #home {
    .info {
      .info-text {
        top: 90px;
        left: 180px;
      }

      h2 {
        font-size: 3em;
      }

      h1 {
        font-size: 4.8em;
      }
    }

    .main-img {
      margin-right: 120px;
    }
  }
}

@media only screen and (max-width: 1504px) {
  #home {
    .info {
      img {
        width: 90%;
      }

      .info-text {
        left: 126px;
        top: 50px;
      }

      h2 {
        font-size: 2.8em;
      }

      h1 {
        font-size: 4.5em;
      }
    }

    .main-img {
      margin-right: 50px;

      img {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  #home {
    .info {
      img {
        width: 95%;
      }

      .info-text {
        left: 120px;
      }

      h1 {
        font-size: 4.2em;
      }
    }

    .main-img {
      margin-right: 30px;
    }
  }
}

@media only screen and (max-width: 1350px) {
  #home {
    .info {
      .info-text {
        top: 70px;
      }
    }
  }
}

@media only screen and (max-width: 1260px) {
  #home {
    .info {
      .info-text {
        left: 100px;
      }

      h2 {
        font-size: 2.6em;
      }

      h1 {
        font-size: 4em;
      }
    }

    .main-img {
      margin-right: 0px;

      img {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  #home {
    .info {
      img {
        width: 90%;
      }

      .info-text {
        left: 70px;
        top: 50px;
      }

      h2 {
        font-size: 2.3em;
      }

      h1 {
        font-size: 3.7em;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  #home {
    .info {
      .info-text {
        left: 70px;
        top: 50px;
      }

      h2 {
        font-size: 2.1em;
      }

      h1 {
        font-size: 3.4em;
      }
    }
  }
}

@media only screen and (max-width: 1040px) {
  #home {
    .info {
      img {
        width: 85%;
      }

      .info-text {
        left: 50px;
      }

      h1 {
        font-size: 3em;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  #home {
    .info {
      .info-text {
        left: 30px;
        top: 35px;
      }
    }
  }
}

@media only screen and (max-width: 945px) {
  #home {
    flex-direction: column;
    text-align: center;
    margin-top: 20px;

    .info {
      position: static;

      > img {
        display: none;
      }

      .info-text {
        position: static;
      }

      h2 {
        font-size: 1.8em;
      }

      h1 {
        font-size: 2.5em;
        color: #000;

        span {
          color: $primary;
        }
      }
    }

    .main-img {
      margin-top: 30px;
      margin-bottom: 20px;

      img {
        width: 55%;
      }
    }
  }
}

@media only screen and (max-width: 620px) {
  #home {
    .main-img {
      margin-top: 60px;

      img {
        width: 55%;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  #home {
    .main-img {
      margin-top: 80px;

      img {
        width: 65%;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  #home {
    .info {
      h2 {
        font-size: 1.4em;
      }

      h1 {
        font-size: 2.4em;
      }
    }

    .main-img {
      margin-top: 40px;

      img {
        width: 70%;
      }
    }
  }
}

@media only screen and (max-width: 300px) {
  #home {
    .info {
      h2 {
        font-size: 1.4em;
      }

      h1 {
        font-size: 2.2em;
      }
    }

    .main-img {
      img {
        width: 80%;
      }
    }
  }
}

// @media only screen and (max-height: 860px) {
//   #home {
//     .info {
//       img {
//         width: 95%;
//       }

//       .info-text {
//         top: 90px;
//         left: 180px;
//       }

//       h2 {
//         font-size: 3em;
//       }

//       h1 {
//         font-size: 4em;
//       }
//     }

//     .main-img {
//       img {
//         width: 80%;
//       }
//     }
//   }
// }
